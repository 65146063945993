
import styled from 'styled-components';
import background from '../assets/img/background_login.png';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  background-image: linear-gradient(to right, rgba(20,209,170,0.2), rgba(10,82,130,0.4)), url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Modal = styled.div`
  width: 500px;
  height: 560px;
  max-width: 90vw;
  max-height: 80vh;
  margin: auto;
  padding: 40px 60px;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  border-radius: 12px;
  background-color: #fff;

  img{
    margin: 0 auto 15px;
    width: 350px;
    height: auto;
  }

  &.forgot-password{
    max-height: 440px;
    position: relative;

    .back-icon{
      position: absolute;
      left: 15px;
      top: 15px;
      img{
        width: 24px;
        height: 24px;
      }
    }
  }

  @media (max-width: 740px) {
    height: auto;
    padding: 40px 30px;

    img{
      width: 280px;
      margin-bottom: 8px;
    }
  }
`;

export const Title = styled.h1`
  color: #16B499;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 0px;

  @media (max-width: 740px) {
    font-size: 20px;
  }
`;

export const Form = styled.div`
  div{
    display: flex;
    flex-direction: column;
    margin: 16px auto;
  }

  .forgot{
    text-align: right;

    a{
      color: #848484;

      text-align: right;
      font-size: 16px;
      font-style: italic;
      font-weight: 300;
      line-height: normal;
    }
  }

  label{
    color: #143968;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin-bottom: 8px;
  }

  input{
    width: 100%
    height: 50px;
    padding: 12px 20px;
    font-size: 16px;
    box-sizing: border-box;

    border-radius: 16px;
    border: 2px solid #AFAFAF;

    &:focus{
      border-color: #14D299;
      outline: none;
    }
  }

  button{
    width: 100%;
    margin-top: 10px;

    background: #fff;
    border-radius: 32px;
    border: 3px solid #72FFB3;

    color: #14D299;
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    line-height: normal;
    padding: 10px;

    cursor: pointer;
    transition: 0.2s;

    &:hover{
      border-color: #14D299;
    }
  }

  @media (max-width: 740px) {
    div{
      margin: 12px auto;
    }
  
    .forgot{
      a{
        font-size: 14px;
      }
    }
  
    label{
      font-size: 16px;
      margin-bottom: 6px;
    }
  
    input{
      padding: 8px 14px;
      font-size: 14px;
      border: 2px solid #AFAFAF;
    }
  
    button{
      margin-top: 0px;
  
      border-radius: 24px;
      border: 3px solid #72FFB3;
      font-size: 16px;
      padding: 8px;
    }
  }
`;
