import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { auth } from './firebaseConfig';
import BackIcon from '../assets/back-icon2.png';
import { sendPasswordResetEmail, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { 
  Container, 
  Modal, 
  Title, 
  Form
} from "../styles/login";
import Logo from "../assets/img/logo.png";

function ForgotPassword() {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');

  const navigate = useNavigate();
  const [signInEmail, setSignInEmail] = useState();
  const [newPassword, setNewPassword] = useState();

  const signInWithGoogleAuth = () => {
    sendPasswordResetEmail(auth, signInEmail)
    .then(() => {
      alert('Email enviado com sucesso!');
      navigate("/login");
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const changePassword = () => {
    const actionCode = searchParams.get('oobCode');

    verifyPasswordResetCode(auth, actionCode).then(() => {
      confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
        alert('Senha alterada com sucesso!');
        navigate("/login");
      }).catch((error) => {
        const errorMessage = error.message;
        switch (errorMessage) {
          case 'EXPIRED_OOB_CODE':
            alert('O código de recuperação expirou.');
            break;
          case 'WEAK_PASSWORD : Password should be at least 6 characters':
            alert('A senha deve ter pelo menos 6 caracteres.');
            break;
          default:
            alert('Ocorreu um erro ao tentar recuperar sua senha. Tente novamente mais tarde.');
            break;
        }
      });
    }).catch((error) => {
      const errorMessage = error.message;
      switch (errorMessage) {
        case 'EXPIRED_OOB_CODE':
          alert('O código de recuperação expirou.');
          break;
        case 'WEAK_PASSWORD : Password should be at least 6 characters':
          alert('A senha deve ter pelo menos 6 caracteres.');
          break;
        default:
          alert('Ocorreu um erro ao tentar recuperar sua senha. Tente novamente mais tarde.');
          break;
      }
    });
  }

  return (
    <Container>
      <Modal className="forgot-password">
        <div className="back-icon">
        <Link to={`/login`}><img src={BackIcon} alt="voltar" /></Link>
        </div>
        <Link to="/login">
          <img src={Logo} alt="logo Inovally" />
        </Link>
        <Title>Recupere sua senha</Title>
        <Form>
          {mode === 'resetPassword' ? 
            <>
              <div>
                <label>Nova senha</label>
                <input
                  type="password"
                  name="password"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                  />
              </div>
              <button onClick={() => changePassword()}>Enviar link de recuperação</button>
            </>
            :
            <>
              <div>
                <label>Usuário</label>
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={signInEmail}
                  onChange={e => setSignInEmail(e.target.value)}
                  />
              </div>
              <button onClick={() => signInWithGoogleAuth()}>Enviar link de recuperação</button>
            </>
          }
        </Form>
      </Modal>
    </Container>
  );
}

export default ForgotPassword;
