import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from './firebaseConfig';
import Cookies from "js-cookie";
import moment from "moment";
import { 
  setPersistence, 
  signInWithEmailAndPassword, 
  indexedDBLocalPersistence,
} from "firebase/auth";

import { 
  Container, 
  Modal, 
  Title, 
  Form
} from "../styles/login";
import Logo from "../assets/img/logo.png";

function Login() {
  const navigate = useNavigate();
  const [signInEmail, setSignInEmail] = useState('');
  const [signInPassword, setSignInPassword] = useState('');

  const signInWithGoogleAuth = () => {
    setPersistence(auth, indexedDBLocalPersistence)
    .then(() => {
      return signInWithEmailAndPassword(auth, signInEmail, signInPassword);
    }).then(() => {
      
    Cookies.set("@innovally", JSON.stringify(
      {
        email: auth.currentUser.email,
        token: auth.currentUser.accessToken, 
      }),
      { expires: moment().utc(true).add(24, "hours").toDate()}
    );

    navigate("/")
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
  }

  return (
    <Container>
      <Modal>
        <img src={Logo} alt="logo Inovally" />
        <Title>Acesse sua conta</Title>
        <Form>
          <div>
            <label>Usuário</label>
            <input
              type="text"
              name="email"
              placeholder="Email"
              onChange={e => setSignInEmail(e.target.value)}
            />
          </div>
          <div>
            <label>Senha</label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={e => setSignInPassword(e.target.value)}
            />
          </div>
          <div className="forgot">
            <Link to={`/esqueci-minha-senha`}>Esqueceu sua senha?</Link>
          </div>
          <button onClick={() => signInWithGoogleAuth()}>Login na sua conta Inovally</button>
        </Form>
      </Modal>
    </Container>
  );
}

export default Login;
